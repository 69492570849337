import React from "react";

import {DrvLinkPath} from "../Shared/DrvLink/DrvLinkPath";
import {PrivacyPolicyData} from "./PrivacyPolicy.data";
import {CoverContainer, Title, Description } from "../PrivacyPolicy/PrivacyPolicy.styles";

const PrivacyPolicy = () => {
    return (
        <>
            {/*CONTENT*/}
            <CoverContainer background='linear-gradient(268deg, #f16266, #bc474c)'>
                <div className='container mx-auto flex flex-col justify-center'>
                    {PrivacyPolicyData[0].title}
                </div>
            </CoverContainer>

            {/*CONTENT*/}
            <div className='container mx-auto py-10'>
                <DrvLinkPath items={[{ text: 'Drivosity', link: '/', isLink: true }, { text: 'Privacy Policy', link: '/privacy-policy', isLink: false }]} />
            </div>

            {/*CONTENT*/}
            <div className='container mx-auto'>
                <div className='mb-16'>
                    <div className='drv-subtitle'>
                        {PrivacyPolicyData[1].title}
                    </div>
                    <Description>
                        <div className='mb-16'>
                            {PrivacyPolicyData[1].content}
                        </div>
                        <div className='mb-16'>
                            {PrivacyPolicyData[2].content}
                        </div>
                        {PrivacyPolicyData[3].content}
                    </Description>
                </div>

                <div className='mb-16'>
                    <Title>
                        {PrivacyPolicyData[4].title}
                    </Title>
                    <Description>
                        <div className='mb-16'>
                            {PrivacyPolicyData[4].content}
                        </div>
                        {PrivacyPolicyData[5].content}
                    </Description>
                </div>

                <div className='mb-16'>
                    <Title>
                        {PrivacyPolicyData[6].title}
                    </Title>
                    <Description>
                        <div className='mb-16'>
                            {PrivacyPolicyData[6].content}
                        </div>
                        <div className='mb-16'>
                            {PrivacyPolicyData[7].content}
                        </div>
                        <div className='mb-16'>
                            {PrivacyPolicyData[8].content}
                        </div>
                        <div className='mb-16'>
                            {PrivacyPolicyData[9].content}
                        </div>
                        <div className='mb-16'>
                            {PrivacyPolicyData[10].content}
                        </div>
                        <div className='mb-16'>
                            {PrivacyPolicyData[11].content}
                        </div>
                        <div className='mb-16'>
                            {PrivacyPolicyData[12].content}
                        </div>
                    </Description>
                </div>
                
                <div className='mb-16'>
                    <Title>
                        {PrivacyPolicyData[13].title}
                    </Title>
                    <Description>
                        <div className='mb-16'>
                            {PrivacyPolicyData[13].content}
                        </div>
                        <div className='mb-16'>
                            {PrivacyPolicyData[14].content}
                        </div>
                        <div className='mb-16'>
                            {PrivacyPolicyData[15].content}
                        </div>
                        <div className='mb-16'>
                            {PrivacyPolicyData[16].content}
                        </div>
                        <div className='mb-16'>
                            {PrivacyPolicyData[17].content}
                        </div>
                        <div className='mb-16'>
                            {PrivacyPolicyData[18].content}
                        </div>
                        <div className='mb-16'>
                            {PrivacyPolicyData[19].content}
                        </div>
                        <div className='mb-16'>
                            {PrivacyPolicyData[20].content}
                        </div>
                        <div className='mb-16'>
                            {PrivacyPolicyData[21].content}
                        </div>
                        <div className='mb-16'>
                            {PrivacyPolicyData[22].content}
                        </div>
                        <div className='mb-16'>
                            {PrivacyPolicyData[23].content}
                        </div>
                    </Description>
                </div>

                <div className='mb-16'>
                    <Title>
                        {PrivacyPolicyData[24].title}
                    </Title>

                    <Description>
                        <div className='mb-16'>
                            {PrivacyPolicyData[24].content}
                        </div>
                    </Description>
                </div>

                <div className='mb-16'>
                    <Title>
                        {PrivacyPolicyData[25].title}
                    </Title>
                    <Description>
                        <div className='mb-16'>
                            {PrivacyPolicyData[25].content}
                        </div>
                        {PrivacyPolicyData[26].content}
                    </Description>
                </div>

                <div className='mb-16'>
                    <Title>
                        {PrivacyPolicyData[27].title}
                    </Title>
                    <Description>
                        <div className='mb-16'>
                            {PrivacyPolicyData[27].content}
                        </div>
                        <div className='mb-16'>
                            {PrivacyPolicyData[28].content}
                        </div>
                    </Description>
                </div>

                <div className='mb-16'>
                    <Title>
                        {PrivacyPolicyData[29].title}
                    </Title>
                    <Description>
                        <div className='mb-16'>
                            {PrivacyPolicyData[29].content}
                        </div>
                        <div className='mb-16'>
                            {PrivacyPolicyData[30].content}
                        </div>
                        <div className='mb-16'>
                            {PrivacyPolicyData[31].content}
                        </div>
                        <div className='mb-16'>
                            {PrivacyPolicyData[32].content}
                        </div>
                        <div className='mb-16'>
                            {PrivacyPolicyData[33].content}
                        </div>
                        <div className='mb-16'>
                            {PrivacyPolicyData[34].content}
                        </div>
                        <div className='mb-16'>
                            {PrivacyPolicyData[35].content}
                        </div>
                    </Description>
                </div>

                <div className='mb-16' id='go-privacy-and-policy'>
                    <Title>
                        {PrivacyPolicyData[36].title}
                    </Title>
                    <Description>
                        <div className='mb-16'>
                            {PrivacyPolicyData[36].content}
                        </div>
                    </Description>
                </div>
                <div className='mb-16'>
                    <Title>
                        {PrivacyPolicyData[37].title}
                    </Title>

                    <Description>
                        <div className='mb-16'>
                            {PrivacyPolicyData[37].content}
                        </div>
                    </Description>
                </div>

                <div className='mb-16'>
                    <Title>
                        {PrivacyPolicyData[38].title}
                    </Title>
                    <Description>
                        <div className='mb-16'>
                            {PrivacyPolicyData[38].content}
                        </div>
                        <div className='mb-16'>
                            {PrivacyPolicyData[39].content}
                        </div>
                        <div className='mb-16'>
                            {PrivacyPolicyData[40].content}
                        </div>
                        <div className='mb-16'>
                            {PrivacyPolicyData[41].content}
                        </div>
                        <div className='mb-16'>
                            {PrivacyPolicyData[42].content}
                        </div>
                    </Description>
                </div>

                <div className='mb-16'>
                    <Title>
                        {PrivacyPolicyData[43].title}
                    </Title>
                    <Description>
                        <div className='mb-7'>
                            {PrivacyPolicyData[43].content}
                        </div>
                        {PrivacyPolicyData[44].content}                    
                    </Description>
                </div>
            </div>
        </>
    )
}

export default PrivacyPolicy;
