import React from "react";
import {Helmet} from "react-helmet";

import Layout from "../components/Layout";
import PrivacyPolicy from "../components/PrivacyPolicy/PrivacyPolicy";

import Favicon from "../images/Favicon.png";

const PrivacyPolicyPage = () => {
    return (
        <>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Privacy Policy - Drivosity</title>
                <link rel="icon" type="image/png" sizes="32x32" href={Favicon}/>
                <meta name="description"
                      content="Read Drivosity's Privacy Policy to learn how we collect, use, and protect your personal information. Understand our data handling practices for our website, app, and services. Review our privacy terms here."/>
            </Helmet>
            <Layout component={<PrivacyPolicy/>}/>
        </>
    )
}

export default PrivacyPolicyPage;
